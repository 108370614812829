@import "./../../../../style/index.scss";

.card {
  font-family: "Lato";
  font-style: normal;
  @include display-flex(row);
  width: 40rem;
  transition: all ease 0.3s;
  margin-bottom: 1em;
  cursor: pointer;
  &:hover {
    transform: scale(1.03);
  }
  @include respond-to(desktop-lg) {
    width: 30rem;
  }
  @include respond-to(desktop) {
    width: 25rem;
  }
  @media only screen and (max-width: 768px) {
    width: $windowWidth * 0.8;
  }
  .left {
    @include display-flex(row, flex-start, flex-start);
    width: 50%;
    .img {
      width: 100%;
      max-height: 25rem;
      object-fit: contain;
    }
    @include respond-to(desktop-lg) {
      width: 45%;
    }
    @include respond-to(desktop) {
      width: 40%;
    }
  }
  .right {
    width: 40%;
    @include display-flex(row, center, center);
    white-space: wrap;
    padding: 0 1em;
    @include respond-to(desktop-lg) {
      width: 52%;
    }
    @include respond-to(desktop) {
      width: 60%;
    }
    @media only screen and (max-width: 414px) {
      padding: 0;
      padding-left: 1em;
      width: 50%;
    }
    p {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.5rem;
      letter-spacing: 0.02em;
      color: #aea9ba;
      @include respond-to(desktop-lg) {
        font-size: 1.1rem;
      }
      @include respond-to(desktop) {
        font-size: 1rem;
      }
      @media only screen and (max-width: 414px) {
        font-size: 0.8rem;
      }
    }
  }
}
