@import "./../../style/index.scss";

.homePage {
  width: $windowWidth;
  box-sizing: border-box;
  .downloadBtn {
    overflow: hidden;
    position: fixed;
    top: 50%;
    width: 12rem;
    right: -4.8rem;
    z-index: 9999;
    border: none;
    outline: none;
    color: $white;
    background-color: $primaryColor;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 400;
    padding: 0.5em 1em;
    transition: all ease-in-out 0.2s;
    &:hover {
      background-color: $secondaryColor;
    }
    @include respond-to(mobile) {
      width: 9rem;
      right: -3.6rem;
      font-size: 0.8em;
    }
  }
}
