@import "./../../../style/index.scss";

.ourPartners {
  width: 100%;
  overflow: hidden;
  padding: $commonPaddingForSides;
  box-sizing: border-box;
  @include display-flex(row, $align: center);
  @include respond-to(tablet) {
    @include display-flex(column, flex-start, flex-start);
    padding: $xxxxl $commonPaddingForSides;
  }
  @include respond-to(mobile) {
    @include display-flex(column, flex-start, flex-start);
    padding: $lg $commonPaddingForSides;
  }
  @include respond-to(small-mobile) {
    padding: $xs $commonPaddingForSides;
  }
  .heading {
    width: 50%;
    box-sizing: border-box;
    @include respond-to(desktop) {
      width: $windowWidth * 0.52;
    }
    @include respond-to(tablet) {
      width: $windowWidth * 0.9;
    }
    @include respond-to(mobile) {
      width: $windowWidth * 0.9;
    }
  }
  .partners {
    width: 70%;
    .row {
      @include display-flex(row, flex-start, center);
      column-gap: 10%;
      flex-wrap: wrap;
      .partnerImg {
        object-fit: contain;
        width: 10rem;
        mix-blend-mode: color-burn;
        transition: all ease 0.3s;
        &:hover {
          transform: scale(1.2);
          transition: all ease 0.3s;
        }
      }
    }
    @include respond-to(desktop-lg) {
      .row {
        column-gap: 7%;
        .partnerImg {
          height: 9rem;
          width: 7.8rem;
        }
      }
    }
    @include respond-to(desktop) {
      .row {
        column-gap: 3%;
        .partnerImg {
          height: 7rem;
          width: 6rem;
        }
      }
    }
    @include respond-to(tablet) {
      width: 100%;
      .row {
        justify-content: flex-start;
        column-gap: 7%;
        .partnerImg {
          height: 8.5rem;
          width: 8rem;
        }
      }
    }
    @include respond-to(mobile) {
      width: 100%;
      .row {
        justify-content: flex-start;
        column-gap: 10%;
        .partnerImg {
          // height: 5rem;
          width: 3rem;
        }
      }
    }
    @include respond-to(small-mobile) {
      .row {
        justify-content: flex-start;
        column-gap: 8%;
        .partnerImg {
          height: 4rem;
          width: 3rem;
        }
      }
    }
    // @media only screen and (max-width: 768px) {
    //   width: 80%;
    //   box-sizing: border-box;
    //   .row {
    //     @include display-flex(row, space-evenly, center);
    //     .partnerImg {
    //       height: 10em;
    //       width: 8em;
    //     }
    //   }
    // }
    // @media only screen and (max-width: 660px) {
    //   .row {
    //     .partnerImg {
    //       height: 8em;
    //       width: 6em;
    //     }
    //   }
    // }
    // @media only screen and (max-width: 540px) {
    //   .row {
    //     .partnerImg {
    //       height: 7em;
    //       width: 5em;
    //     }
    //   }
    // }
    // @media only screen and (max-width: 414px) {
    //   .row {
    //     .partnerImg {
    //       height: 6em;
    //       width: 4em;
    //     }
    //   }
    // }
  }
}
