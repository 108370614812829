@import "./../../../style/index.scss";

.parent {
  position: relative;
  box-sizing: border-box;
  width: $windowWidth;
  @include viewportHeight(65);
  padding: 0 $commonPaddingForSides;
  padding-top: 1rem;
  @include respond-to(desktop-lg) {
    @include viewportHeight(60);
  }
  @include respond-to(tablet) {
    @include viewportHeight(34);
  }
  @include respond-to(mobile) {
    height: auto;
    padding: 0;
  }
  .achievements {
    width: 100%;
    @include display-flex(row);
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .btnNext,
  .btnPrev {
    @include display-flex(row, center, center);
    cursor: pointer;
    position: absolute;
    outline: none;
    border: none;
    background: none;
    z-index: 10;
    bottom: -1%;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 3rem;
    width: 3rem;
    &:hover,
    &:focus {
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }
  }
  .btnNext {
    right: 40%;
    padding-left: 1em;
  }
  .btnPrev {
    left: 40%;
    padding-right: 1em;
  }
  @include respond-to(desktop-xxlg) {
    .btnNext,
    .btnPrev {
      height: 3.5rem;
      width: 3.5rem;
      bottom: 0%;
    }
  }
  @include respond-to(desktop-lg) {
    .btnNext,
    .btnPrev {
      height: 2.5rem;
      width: 2.5rem;
      bottom: -4%;
    }
  }
  @include respond-to(desktop) {
    .btnNext,
    .btnPrev {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
  @include respond-to(tablet) {
    .btnNext,
    .btnPrev {
      height: 2.5rem;
      width: 2.5rem;
    }
    .btnNext {
      right: 35%;
    }
    .btnPrev {
      left: 35%;
    }
  }
  @include respond-to(mobile) {
    .btnNext,
    .btnPrev {
      display: none;
    }
  }
  .carouselDots {
    position: absolute;
    @include display-flex(row, space-between, center);
    @include viewportWidth(4);
    left: 48%;
    bottom: 5%;
    .dot,
    .highlightedDot {
      cursor: pointer;
      background-color: $fontColor;
      border-radius: 50%;
      height: 0.8rem;
      width: 0.8rem;
      z-index: 2;
      opacity: 0.4;
      transition: all ease-in-out 0.2s;
    }
    .highlightedDot {
      opacity: 1;
      transform: scale(1.2);
    }

    @include respond-to(desktop-lg) {
      @include viewportWidth(5);
      left: 47.5%;
      bottom: 2.5%;
    }
    @include respond-to(desktop) {
      @include viewportWidth(6);
      left: 47%;
      bottom: 7.5%;
      .dot,
      .highlightedDot {
        height: 0.7rem;
        width: 0.7rem;
      }
    }
    @include respond-to(tablet) {
      @include viewportWidth(8);
      left: 46%;
      bottom: 8.5%;
      .dot,
      .highlightedDot {
        height: 0.7rem;
        width: 0.7rem;
      }
    }
    @include respond-to(mobile) {
      @include viewportWidth(14);
      left: 43%;
      .dot,
      .highlightedDot {
        height: 0.6rem;
        width: 0.6rem;
      }
    }
  }
}
