@import "./../../../style/index.scss";

.displayContainer {
  box-sizing: border-box;
  margin-top: 1rem;
  flex-wrap: wrap;
  .displayRow {
    @include display-flex(row, flex-start, center);
    flex-wrap: wrap;
    column-gap: 5%;
  }
  .noProducts {
    background-color: $greyText;
    width: 100%;
    padding: 1em;
    font-size: 1.2rem;
    color: $white;
  }
}
