@import "./../../../../style/index.scss";

.cardContainer {
  .card {
    width: 100%;
    height: 100%;
    padding: 1em;
    box-sizing: border-box;
    .cardImg {
      height: 14rem;
      object-fit: scale-down;
      box-sizing: border-box;
      transform: scale(0.9);
    }
  }
}
