@import "./../../style/index.scss";

.home {
  height: $windowHeight;
  width: $windowWidth;
  margin: 0;
  box-sizing: border-box;
  .homeBody {
    box-sizing: border-box;
    .spaceHeader {
      height: 6rem;
      box-sizing: border-box;
      @include respond-to(tablet) {
        height: 5.5em;
      }
      @include respond-to(mobile) {
        height: 5rem;
      }
      @include respond-to(small-mobile) {
        height: 4.5rem;
      }
    }
    .downloadBtn {
      overflow: hidden;
      position: fixed;
      top: 50%;
      width: 12rem;
      right: -4.8rem;
      z-index: 9999;
      border: none;
      outline: none;
      color: $white;
      background-color: $primaryColor;
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      cursor: pointer;
      font-size: 1.1rem;
      font-weight: 400;
      padding: 0.5em 1em;
      transition: all ease-in-out 0.2s;
      &:hover {
        background-color: $secondaryColor;
      }
      @include respond-to(mobile) {
        width: 9rem;
        right: -3.6rem;
        font-size: 0.8em;
      }
    }
  }
}
