@import "./../../../style/index.scss";

@mixin fontFamily {
  font-family: "Lato";
  font-style: normal;
  font-size: 1.01em;
}

.detailDescriptionContainer {
  box-sizing: border-box;
  margin-top: 2.5rem;
  .productDetailTable {
    box-sizing: border-box;
    margin-top: 2.5rem;
    .thead {
      background-color: #eeeeee;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.1em;
      border: none;
      border: 1px solid rgba(174, 169, 186, 0.2);
      .trow {
        border: none;
        .tcell {
          @include fontFamily;
          border: none;
        }
      }
    }
    .tbody {
      .trowd {
        border: none;
        border-right: 1px solid rgba(174, 169, 186, 0.2);
        border-left: 1px solid rgba(174, 169, 186, 0.2);
        .tcelld {
          @include fontFamily;
          color: $fontColor;
          border-bottom: 1px solid rgba(174, 169, 186, 0.2);
        }
      }
    }
  }
  .title {
    color: $greyText;
    font-weight: 600;
    margin: 1em 0;
  }
  .images {
    margin-top: 2em;
    margin-left: 1em;
    @include display-flex(row, flex-start, flex-start);
    flex-wrap: wrap;
    column-gap: 1em;
    .image {
      object-fit: contain;
      max-width: 25rem;
    }
  }
  .images2 {
    width: 100%;
    margin: 1em 0;
    @include display-flex(row, flex-start, flex-start);
    flex-wrap: wrap;
    column-gap: 1em;
    .image2 {
      object-fit: contain;
      max-width: 100%;
    }
  }
  .dataAfterTable {
    color: rgb(110, 110, 110);
    margin-top: 0.5rem;
    p {
      font-size: 1.1em;
      margin: 0;
      @include fontFamily;
    }
  }
  .downloadDatasheet {
    margin-top: 1em;
    .downloadBtn {
      cursor: pointer;
      border: none;
      outline: none;
      background-color: transparent;
      color: $secondaryColor;
      font-size: 1.1rem;
      transition: 0.2s;
      text-decoration: underline;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
